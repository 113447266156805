import * as React from 'react'
import { FunctionComponent, memo, useState } from 'react'
import { Formik } from 'formik'

import Modal from '../../components/Modal'
import Input from '../../components/Input'
import Button from '../../components/Button'
import DropdownSelect from '../../components/DropdownSelect'
import {
  IBranch,
  ReplenishmentOrdinals,
  ReplenishmentWeekDays,
} from '../../types/user'
import useThunkDispatch from '../../common/useThunkDispatch'
import { createAccountBranch } from '../accountsActions'
import Toggle from '../../components/Toggle'

export interface INewBranch extends IBranch {
  sourceBranchId?: number
  copyInventory?: boolean
  copyDivisions?: boolean
  copyTeams?: boolean
  copyManagers?: boolean
  copyReplenishmentPlan?: boolean
  copyPurchaseHistory?: boolean
  copyOrders?: boolean
}

interface IOwnProps {
  branch: INewBranch
  branches: IBranch[]
  accountId: number
  onClose(changed?: any): void
}

interface IReplenishmentOrdinalsOption {
  label: string
  value: ReplenishmentOrdinals
}

interface IReplenishmentWeekDaysOption {
  label: string
  value: ReplenishmentWeekDays
}

export const FREQUENCY: IReplenishmentOrdinalsOption[] = [
  { label: 'First', value: 'first' },
  { label: 'Second', value: 'second' },
  { label: 'Third', value: 'third' },
  { label: 'Fourth', value: 'fourth' },
  { label: 'Last', value: 'last' },
]

export const DAY: IReplenishmentWeekDaysOption[] = [
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
]

const AccountBranchNew: FunctionComponent<IOwnProps> = ({
  branch,
  branches,
  accountId,
  onClose,
}) => {
  const dispatch = useThunkDispatch()
  const [showSourceField, setShowSourceField] = useState(false)

  const onSubmit = async (attrs: INewBranch, actions: any) => {
    actions.setSubmitting(true)

    if (!attrs.replenishmentOrdinal || !attrs.replenishmentWeekDay) {
      alert('Monthly replenishment can not be empty!')
      return
    }

    try {
      const createAttrs: any = {
        accountId,
        name: attrs.name,
        navId: attrs.navId,
        address: attrs.address,
        address2: attrs.address2,
        city: attrs.city,
        zip: attrs.zip,
        state: attrs.state,
        replenishmentOrdinal: attrs.replenishmentOrdinal,
        replenishmentWeekDay: attrs.replenishmentWeekDay,
        showTechPrice: attrs.showTechPrice,
        sourceBranchId: attrs.sourceBranchId,
        copyDivisions: attrs.copyDivisions,
        copyTeams: attrs.copyTeams,
        copyInventory: attrs.copyInventory,
        copyManagers: attrs.copyManagers,
        copyReplenishmentPlan: attrs.copyReplenishmentPlan,
        copyPurchaseHistory: attrs.copyPurchaseHistory,
        copyOrders: attrs.copyOrders,
        invoiceMappingRow: attrs.invoiceMappingRow,
        shipmentApproveFlow: attrs.shipmentApproveFlow,
      }

      const errors = await dispatch(createAccountBranch(createAttrs))
      if (!errors) window.location.reload()
      else {
        actions.setErrors(errors)
      }
    } catch (e) {
      alert(e && e.message ? e.message : 'Server Error')
    }
    actions.setSubmitting(false)
  }

  return (
    <Modal onClose={onClose} title="Branch Info">
      <Formik
        initialValues={branch}
        onSubmit={onSubmit}
        render={({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          dirty,
        }) => (
          <form className="form" onSubmit={handleSubmit}>
            <div className="form_group">
              <Input
                onChange={handleChange('name')}
                name="name"
                label="Name"
                value={values.name}
              />
            </div>
            <div className="form_group">
              <Input
                onChange={handleChange('navId')}
                name="nav_id"
                label="NAV ID"
                value={values.navId}
              />
            </div>
            <div className="form_group">
              <Input
                name="address"
                label="Address 1"
                onChange={handleChange('address')}
              />
            </div>
            <div className="form_group">
              <Input
                name="address2"
                label="Address 2"
                onChange={handleChange('address2')}
              />
            </div>
            <div className="form_row">
              <div className="form_group form_col">
                <Input
                  name="city"
                  label="City"
                  onChange={handleChange('city')}
                />
              </div>
              <div className="form_group form_col">
                <Input
                  name="state"
                  label="State"
                  onChange={handleChange('state')}
                />
              </div>
              <div className="form_group form_col">
                <Input name="zip" label="ZIP" onChange={handleChange('zip')} />
              </div>
            </div>
            <div className="form_group">
              <label className="input_label">Monthly replenishment on</label>

              <div className="form_row">
                <DropdownSelect<string | undefined>
                  value={values.replenishmentOrdinal}
                  options={FREQUENCY as any}
                  onChange={handleChange('replenishmentOrdinal')}
                  dropdownClassName="form_col input"
                  dropdownValueClassName="input_input -flex"
                />

                <DropdownSelect<string | undefined>
                  value={values.replenishmentWeekDay}
                  options={DAY as any}
                  onChange={handleChange('replenishmentWeekDay')}
                  dropdownClassName="form_col input"
                  dropdownValueClassName="input_input -flex"
                />
              </div>
            </div>
            <div className="form_group">
              <Input
                onChange={handleChange('invoiceMappingRow')}
                name="invoiceMappingRow"
                label="Invoices mapping"
              />
            </div>

            <div className="form_group form_toggle">
              <Toggle
                value={values.showTechPrice}
                onChange={() =>
                  handleChange('showTechPrice')(!values.showTechPrice)
                }
                option={{
                  value: true,
                  label: 'Show pricing for technicians',
                }}
              />
            </div>

            <div className="form_group form_toggle">
              <Toggle
                value={values.shipmentApproveFlow}
                onChange={() =>
                  handleChange('shipmentApproveFlow')(
                    !values.shipmentApproveFlow
                  )
                }
                option={{
                  value: true,
                  label: 'Manual Shipment Approval',
                }}
              />
            </div>

            <div className="form_group form_toggle">
              <Toggle
                value={showSourceField}
                onChange={() => setShowSourceField(!showSourceField)}
                option={{
                  value: true,
                  label: 'Copy settings from existing branch',
                }}
              />
            </div>

            {showSourceField && (
              <>
                <label className="input_label" style={{ fontWeight: 'bold' }}>
                  Branch to copy
                </label>
                <div className="form_group">
                  <label className="input_label">Title</label>

                  <DropdownSelect<number | undefined>
                    value={values.sourceBranchId}
                    options={branches.map(b => ({
                      label: b.name,
                      value: b.id,
                    }))}
                    dropdownClassName="input"
                    dropdownValueClassName="input_input -flex"
                    onChange={handleChange('sourceBranchId')}
                  />
                </div>

                <div className="form_group form_toggle">
                  <Toggle
                    value={values.copyDivisions}
                    onChange={() =>
                      handleChange('copyDivisions')(!values.copyDivisions)
                    }
                    option={{
                      value: true,
                      label: 'Copy Divisions',
                    }}
                  />
                </div>

                <div className="form_group form_toggle">
                  <Toggle
                    value={values.copyTeams}
                    onChange={() =>
                      handleChange('copyTeams')(!values.copyTeams)
                    }
                    option={{
                      value: true,
                      label: 'Copy Teams',
                    }}
                  />
                </div>

                <div className="form_group form_toggle">
                  <Toggle
                    value={values.copyManagers}
                    onChange={() =>
                      handleChange('copyManagers')(!values.copyManagers)
                    }
                    option={{
                      value: true,
                      label: 'Copy Managers',
                    }}
                  />
                </div>

                <div className="form_group form_toggle">
                  <Toggle
                    value={values.copyInventory}
                    onChange={() =>
                      handleChange('copyInventory')(!values.copyInventory)
                    }
                    option={{
                      value: true,
                      label: 'Copy Inventory',
                    }}
                  />
                </div>

                <div className="form_group form_toggle">
                  <Toggle
                    value={values.copyPurchaseHistory}
                    onChange={() =>
                      handleChange('copyPurchaseHistory')(
                        !values.copyPurchaseHistory
                      )
                    }
                    option={{
                      value: true,
                      label: 'Copy Purchases History',
                    }}
                  />
                </div>

                <div className="form_group form_toggle">
                  <Toggle
                    value={values.copyOrders}
                    onChange={() =>
                      handleChange('copyOrders')(!values.copyOrders)
                    }
                    option={{
                      value: true,
                      label: 'Copy Orders',
                    }}
                  />
                </div>

                <div className="form_group form_toggle">
                  <Toggle
                    value={values.copyReplenishmentPlan}
                    onChange={() =>
                      handleChange('copyReplenishmentPlan')(
                        !values.copyReplenishmentPlan
                      )
                    }
                    option={{
                      value: true,
                      label: 'Copy Replenishment Plan',
                    }}
                  />
                </div>
              </>
            )}

            <div className="form_controls">
              <div className="form_controls-container">
                <Button
                  disabled={isSubmitting}
                  onClick={onClose}
                  color="default-link"
                  className="form_control -size -right"
                >
                  Cancel
                </Button>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting || !dirty}
                  type="submit"
                  color="success"
                  className="form_control -size"
                >
                  Create
                </Button>
              </div>
            </div>
          </form>
        )}
      />
    </Modal>
  )
}

export default memo(AccountBranchNew)
